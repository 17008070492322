<template>
    <div>
        <table cellspacing="0" cellpadding="0" border="0" class="" style="width: 100%;">
            <colgroup>

            </colgroup>
            <thead class="bg-brown">
            <tr class="">
                <th colspan="1" rowspan="1" >
                    <div class="cell">时间</div>
                </th>
                <th colspan="1" rowspan="1">
                    <div class="cell">状态</div>
                </th>
                <th colspan="1" rowspan="1" >
                    <div class="cell">用途</div>
                </th>
                <th colspan="1" rowspan="1" >
                    <div class="cell">操作</div>
                </th>
                <th colspan="1" rowspan="1" >
                    <div class="cell">剩余金额</div>
                </th>
            </tr>
            </thead>
            <tr class="" v-for="(item,index) in historyList" :key="index">
                <!-- 时间 -->
                <td rowspan="1" colspan="1" class="cell">
                    <div class=" d-flex align-items-center">
                        <div class="mr-2">{{item.created_at.slice(5,10)}}</div>
                    </div>
                </td>
                <!-- 状态 -->
                <td rowspan="1" colspan="1" class="cell">
                    <div >{{item.type_alias}}</div>
                </td>
                <!-- 用途 -->
                <td rowspan="1" colspan="1" class="cell">
                    <div >{{item.change_type_alias}}</div>
                </td>
                <!-- 操作金额 -->
                <td rowspan="1" colspan="1" class="cell">
                    <div ><money/>{{item.bean}}</div>
                </td>
                <!-- 金额 -->
                <td rowspan="1" colspan="1" class="cell">
                    <div ><money/>{{item.final_bean}}</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: "indexPkTable",
        data() {
            return {}
        },
        props:{
            historyList:{
                type:Array,
                default(){return {}}
            }
        }
    }
</script>

<style scoped lang="scss">
    .cell{
        padding: 10px;
        border-bottom: 1px solid #FFFFFF;
    }
    .active{
        position: relative;
        & img{
            border-radius: 50%;
        }
        &::before{
            content: url("../../../assets/img/success_pk.png");
            position: absolute;
            top: -5px;
            right: 0;

        }
    }
</style>
