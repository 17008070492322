<template>

    <div class="d-flex">
       <div class="content mt-5">
           <index-pk-table :history-list="ChangeRecordsList"></index-pk-table>
         <vacancy v-if="ChangeRecordsList.length == 0"></vacancy>
         <div v-if="ChangeRecordsList.length != 0 && ChangeRecordsList.length < 10"
         style="height: 200px">

         </div>
           <div class="mt-5">
               <el-pagination
                       background
                       layout="prev, pager, next"
                       :total="total"
                       :pager-count="5"
                       :page-size="pageSize"
                       @current-change="currentChange">
               </el-pagination>
           </div>
       </div>

    </div>
</template>

<script>
    // 列表
    import indexPkTable from "./common/indexPkTable";
    import $api from '@/api/user/index'
    export default {
        name: "detail",
        data() {
            return {
                ChangeRecordsList:[],
                total:0,
                pageSize:0
            }
        },
        components:{
            indexPkTable,
        },
        mounted() {
        this.getUsetChange_records()
            },
        methods:{
             getUsetChange_records(page = 1){
              $api.getUsetChange_records(page).then(res =>{
                  if(res.code == 500)return
                  this.ChangeRecordsList = res.data.data
                  this.total = res.data.total
                  this.pageSize = res.data.per_page
              })
            },
            currentChange(val){
                 this.getUsetChange_records(val)
            },
        }
    }
</script>

<style scoped lang="scss">
.content{
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1200px) {
        width: 96%;
        font-size: 10px!important;
    }
}
</style>
